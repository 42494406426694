<template>
  <div class="flex gap-3 items-center">
    <div class="w-[103px] h-2 rounded-[4px] bg-primary-gray-100 relative">
      <div
        class="absolute left-0 bg-primary-purple-600 rounded-[4px] h-full"
        :style="{ width: `${percentage}%` }"
      ></div>
    </div>
    <div class="text-sm text-primary-gray-700 font-inter">{{ percentage }}%</div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 50,
    },
  },
}
</script>
