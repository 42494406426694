<template>
  <div v-if="switchBox" class="flex sm:justify-between flex-row gap-3">
    <section class="flex justify-start md:justify-between gap-2 md:gap-3 w-full sm:w-auto px-0">
      <section
        class="rtl:flex-row-reverse order-1 sm:order-2 border h-9 xl:h-11 md:h-10 border-primary-gray-300 rounded-md flex items-center text-xs md:text-sm"
      >
        <button
          class="p-2 md:p-3 h-8 md:h-10 focus:outline-none"
          :disabled="isDisabled"
          @click="prevOption"
        >
          <icon icon="chevronLeft" color="primary-gray-700" height="20" />
        </button>
        <section class="flex h-8 md:h-10">
          <button
            class="text-primary-gray-700 h-full mx-auto md:ml-0 md:mr-0 px-2 md:px-4 items-center text-sm font-inter font-medium focus:outline-none"
          >
            <div class="truncate max-w-2">{{ currentOptions }}</div>
          </button>
        </section>
        <button
          class="p-2 md:p-3 h-8 md:h-10 focus:outline-none"
          :disabled="isDisabled"
          @click="nextOption"
        >
          <icon icon="chevronRight" color="primary-gray-700" height="20" />
        </button>
      </section>
    </section>
  </div>

  <div v-else class="flex items-center justify-end w-full">
    <button
      class="flex w-6 h-6 bg-primary-purple-100 text-primary-gray-500 rounded-md items-center justify-center"
      :disabled="isDisabled"
      @click="prevOption"
    >
      <span><icon icon="chevronLeft" color="primary-gray-500" /></span>
    </button>
    <div class="text-primary-gray-500 px-2.5 py-[3px] font-medium font-inter text-xs">
      {{ currentOptions }}
    </div>
    <button
      class="flex w-6 h-6 bg-primary-purple-100 text-primary-gray-500 rounded-md items-center justify-center"
      :disabled="isDisabled"
      @click="nextOption"
    >
      <span>
        <icon icon="chevronRight" color="primary-gray-500" />
      </span>
    </button>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import icon from '@src/components/icons/icon.vue'
import isArray from 'lodash/isArray'

const props = defineProps({
  options: {
    type: [Array, Object],
    default: () => [],
  },
  switchBox: {
    type: Boolean,
    default: false,
  },
  selectIndex: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['optionChanged'])
const currentIndex = ref(props.selectIndex)
const currentCampus = ref(null)

const isDisabled = computed(() => !isArray(props.options))

const currentOptions = computed(() => {
  if (isArray(props.options)) {
    return props.options[currentIndex.value]?.title
  } else {
    return props.options?.title
  }
})

const updateCurrentCampusEmit = () => {
  if (!isArray(props.options)) {
    currentCampus.value = props.options?.title
    emit('optionChanged', props.options.id)
  }
}

const prevOption = () => {
  if (isArray(props.options)) {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1
    } else {
      currentIndex.value = props.options.length - 1
    }
    emit('optionChanged', props.options[currentIndex.value].id)
  }
}

const nextOption = () => {
  if (isArray(props.options)) {
    if (currentIndex.value < props.options.length - 1) {
      currentIndex.value += 1
    } else {
      currentIndex.value = 0
    }
    emit('optionChanged', props.options[currentIndex.value].id)
  }
}

watch(
  () => props.selectIndex,
  (newValue) => {
    currentIndex.value = newValue
  },
)

watch(
  () => props.options,
  (newValue) => {
    if (isArray(props.options)) {
      const foundIndex = newValue.findIndex((obj) => obj.title === currentCampus.value)
      if (foundIndex !== -1) {
        currentIndex.value = foundIndex
      }
    } else {
      updateCurrentCampusEmit()
    }
  },
  { immediate: true },
)
</script>
