<template>
  <div>
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Exams" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <CalendarActionsWrapper />
      </div>
    </div>

    <div>
      <div v-if="!isLoading">
        <div v-if="!examsList">
          <NoRecordFound />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(exam, index) in examsList" :key="index">
              <TD>
                {{ exam.examName }}
              </TD>
              <TD>
                {{ exam.startDate }}
              </TD>
              <TD>
                {{ exam.endDate }}
              </TD>
              <TD>
                {{ exam.totalStudendts }}
              </TD>
              <TD>
                <UiStatus :title="exam.status" :success="exam.status === 'Completed'" />
              </TD>
              <TD>
                <UiProgressBar :percentage="exam.resultRate" />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
      <div v-else class="mt-28">
        <Loader :content="true" />
      </div>
      <div class="pb-6 flex mt-2 items-baseline">
        <UiFilterBox :options="sections" class="flex-1" />
        <div class="flex-1">
          <Pagination
            v-if="examsList.length > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
            v-show="!isLoading"
            :key="`ForceRender${forceRender}`"
            :record-limit="5"
            :max-range="paginationCounts(examsList.length, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import UiProgressBar from '@src/components/UiElements/UiProgressBar.vue'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import Heading from '@src/components/Heading.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import icon from '@src/components/icons/icon.vue'
import CalendarActionsWrapper from '@src/components/Calendar/CalendarActionsWrapper.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

const sections = ref([
  { title: 'Campus for Testing' },
  { title: 'Lahore Campus' },
  { title: 'main Campus' },
])
const examsCounts = ref(12)
const isLoading = ref(false)
const forceRender = ref(0)
const currentPage = ref(1)
const recordLimit = 5
const examsList = [
  {
    examName: 'Final',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Upcoming',
    resultRate: 20,
  },
  {
    examName: 'Midterm',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Completed',
    resultRate: 50,
  },
  {
    examName: 'Class Test',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Cancel',
    resultRate: 70,
  },
  {
    examName: 'Quiz Test',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Upcoming',
    resultRate: 40,
  },
  {
    examName: '!st Term',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Upcoming',
    resultRate: 90,
  },
  {
    examName: 'jan Test',
    startDate: '8 jan, 2023',
    endDate: '15 Feb, 2023',
    totalStudendts: 1200,
    status: 'Upcoming',
    resultRate: 25,
  },
]
const tableHeaders = [
  'Exam Name',
  'Start Date',
  'End Date',
  'Total Students',
  'Status',
  'Result Rate',
]
</script>
