<template>
  <div class="bg-white h-auto px-5 rounded-lg">
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Email/SMS Sent" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <CalendarActionsWrapper />
      </div>
    </div>
    <div class="overflow-auto">
      <GraphView :chart-data="graphData" class="min-w-7" />
    </div>
    <div class="pb-6 flex mt-2">
      <UiFilterBox :options="sections" class="justify-center" />
    </div>
  </div>
</template>

<script setup>
import CalendarActionsWrapper from '@src/components/Calendar/CalendarActionsWrapper.vue'
import icon from '@src/components/icons/icon.vue'
import Heading from '@/src/components/Heading.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import GraphView from '@src/router/views/graph/graphView.vue'
import { arrayRange, generateRandomArray } from '@utils/generalUtil'
const sections = [
  { title: 'Campus for Testing' },
  { title: 'Lahore Campus' },
  { title: 'main Campus' },
]
const graphData = {
  type: 'line',
  data: {
    labels: arrayRange(1, 30),
    datasets: [
      {
        label: 'New Users',
        data: generateRandomArray(30, 50, 100),
        borderColor: '#F63D68',
        backgroundColor: '#F63D68',
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: 'Email sent',
        data: generateRandomArray(30, 50, 100),
        borderColor: '#7F56D9',
        backgroundColor: '#7F56D9',
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: {
          target: 'origin',
          above: '#7F56D9)',
        },
      },
      {
        label: 'SMS',
        data: generateRandomArray(30, 50, 100),
        borderColor: '#F79009',
        backgroundColor: '#F79009',
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
      {
        label: 'Announcements',
        data: generateRandomArray(30, 50, 100),
        borderColor: '#1570EF',
        backgroundColor: '#1570EF',
        tension: 0.5,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          padding: 13,
          font: {
            size: 14,
          },
          textAlign: 'center',
        },
      },
    },

    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context) => context.active,
      },
    },
    hoverRadius: 12,
    hoverBackgroundColor: 'yellow',
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    aspectRatio: 100 / 14.3,
  },
}
</script>
