<template>
  <div class="bg-white h-auto px-5 rounded-lg">
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Section Attendance" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <CalendarActionsWrapper />
      </div>
    </div>
    <div class="overflow-auto"><GraphView :chart-data="graphData" class="min-w-7" /></div>
    <div class="pb-6 flex mt-2">
      <UiFilterBox :options="sections" />
      <UiDashboardStats title="Total Students:" value="45" />
    </div>
  </div>
</template>

<script setup>
import Heading from '@/src/components/Heading.vue'
import GraphView from '@src/router/views/graph/graphView.vue'
import UiDashboardStats from '@/src/components/UiElements/UiStatsCountDisplay.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import CalendarActionsWrapper from '@src/components/Calendar/CalendarActionsWrapper.vue'
import icon from '@src/components/icons/icon.vue'
import { generateRandomArray } from '@utils/generalUtil'

const props = defineProps({})
const sections = [
  { title: 'Campus for Testing' },
  { title: 'Lahore Campus' },
  { title: 'main Campus' },
]
const graphData = {
  type: 'bar',
  data: {
    labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Present',
        backgroundColor: '#7F56D9',
        data: generateRandomArray(6, 30, 50),
      },
      {
        label: 'Absent',
        backgroundColor: '#D6BBFB',
        data: generateRandomArray(6, 30, 50),
      },
      {
        label: 'Delay',
        backgroundColor: '#F2F4F7',
        data: generateRandomArray(6, 30, 50),
      },
    ],
  },
  options: {
    responsive: true,
    maxBarThickness: 8,
    maintainAspectRatio: false,
    barPercentage: 0.6,
    categoryPercentage: 0.2,
    aspectRatio: 100 / 80,
    borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 0,
      bottomRight: 0,
    },
    borderSkipped: false,

    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  },
}
</script>
