<template>
  <div class="custom-container mx-auto">
    <div class="flex">
      <div
        v-if="!tabLeftNav"
        class="transform rtl:rotate-180 mt-1 h-12 w-12 bg-white hidden lg:flex justify-center items-center cursor-pointer rounded-md ltr:mr-3 rtl:ml-3"
        @click="mobileLeftBar"
      >
        <icon class="icon" icon="chevronRight" color="primary-green" height="20" width="20" />
      </div>
      <div class="ltr:ml-5 md:ltr:ml-0 rtl:mr-5 md:rtl:mr-0">
        <div class="flex">
          <p class="flex-item text-lg sm:text-2xl text-text-color font-roboto font-medium">
            <span v-if="currentCampusScope === ''" class="font-medium">
              {{ $t('dashboard.Institute Dashboard') }}
            </span>
            <span
              v-if="currentCampusScope !== '' && currentSectionScope === ''"
              class="font-medium"
            >
              {{ $t('dashboard.Campus Dashboard') }}
            </span>
            <span
              v-if="currentCampusScope !== '' && currentSectionScope !== ''"
              class="font-medium"
            >
              {{ $t('dashboard.Section Dashboard') }}
            </span>
          </p>
          <UiOnClickOutside :do="closeToolTip">
            <div
              class="flex-item ml-2 rtl:mr-2 self-center cursor-pointer"
              @click="clickToolTip"
              @mouseover="displayToolTip"
              @mouseleave="hideToolTip"
            >
              <icon
                class="icon"
                icon="infoCircle"
                color="primary-green"
                height="20.617"
                width="16.5"
              />
              <div
                v-if="showToolTip"
                class="absolute -ml-4 mt-3 md:mt-2 rtl:-mr-24 md:rtl:-mr-7 z-10"
              >
                <Tooltip
                  class="w-min sm:w-auto"
                  :title="$t('dashboard.Dashboard')"
                  :text="$t('toolTipInfo.Dashboard')"
                  direction="left"
                />
                <Tooltip
                  class="w-min sm:w-auto"
                  title="Dashboard"
                  text="Dashboard"
                  direction="left"
                />
              </div>
            </div>
          </UiOnClickOutside>
        </div>

        <div class="text-xs sm:text-sm text-menu">
          <span class="cursor-pointer" @click="goToInstitueLevel">
            <span v-i18n="dashboard">Institute</span>
            /
          </span>
          <span v-if="currentCampusScope" class="cursor-pointer" @click="redirectToCampusScope">
            <span v-i18n="dashboard">Campus</span>
            /
          </span>
          <span v-if="currentSectionScope" class="cursor-pointer">
            <span v-i18n="dashboard">Section</span>
            /
          </span>
          <span class="text-text-color">
            {{ $t('dashboard.dashboard') }}
          </span>
        </div>
      </div>
    </div>

    <div class="bg-white rounded-xl mt-10">
      <CampusAttendanceGraph />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <SectionAttendanceGraph />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <PeriodAttendanceGraph />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <TimetableGraph />
    </div>
    <div class="bg-white rounded-xl mt-10 px-5">
      <ExamsTable />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <FeeManagerGraph />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <ExpenseGraph />
    </div>
    <div class="bg-white rounded-xl mt-10">
      <EmailAndSmsGraph />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import icon from '@components/icons/icon.vue'
import Tooltip from '@components/tooltip.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import CampusAttendanceGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/CampusAttendanceGraph.vue'
import SectionAttendanceGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/SectionAttendanceGraph.vue'
import PeriodAttendanceGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/PeriodAttendanceGraph.vue'
import TimetableGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/TimetableGraph.vue'
import ExamsTable from '@src/router/views/dashboard/DashboardGraphs/DashboardGraph/ExamsTable.vue'
import FeeManagerGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/FeeManagerGraph.vue'
import ExpenseGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/ExpenseGraph.vue'
import EmailAndSmsGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/EmailAndSmsGraph.vue'
import tooltipMixin from '@/src/mixins/components/tooltip-mixin.js'

export default {
  page: {
    title: 'Dashboard',
    meta: [{ name: 'description', content: 'Dashboard' }],
  },
  components: {
    icon,
    CampusAttendanceGraph,
    SectionAttendanceGraph,
    PeriodAttendanceGraph,
    TimetableGraph,
    FeeManagerGraph,
    ExpenseGraph,
    EmailAndSmsGraph,
    Tooltip,
    UiOnClickOutside,
    ExamsTable,
  },
  mixins: [tooltipMixin],
  data() {
    return {
      dashboard: 'dashboard',
    }
  },
  computed: {
    isAdmin() {
      return this.activeRole === 'super_admin'
    },
    ...mapState('layout', [
      'activeRole',
      'currentCampusScope',
      'currentSectionScope',
      'tabLeftNav',
    ]),
  },
  mounted() {
    if (this.isAdmin) this.$store.commit('layout/SET_APP_LOADING', false)
  },
  methods: {
    goToInstitueLevel() {
      this.$store.commit('layout/SET_SELECTED_CAMPUSE', '')
      this.$store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', '')
    },
    redirectToCampusScope() {
      this.$store.commit('layout/SET_CURRENT_SELECTED_SECTION_SCOPE', '')
    },
    mobileLeftBar() {
      this.$store.commit('layout/SET_TAB_LEFT_BAR')
    },
  },
}
</script>

<style lang="scss">
.base {
  background: var(--green-light);
  opacity: 1;
}

.campusBase {
  color: #1d273e;
}
.select {
  color: #fff;
  background-color: #267c26;
}
.green {
  background: #267c26;
}
.system {
  color: #fff;
}
</style>
