<template>
  <div class="bg-white h-auto px-5 rounded-lg">
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Expense" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <CalendarActionsWrapper />
      </div>
    </div>
    <div class="overflow-auto">
      <GraphView :chart-data="graphData" class="min-w-7" />
    </div>
    <div class="pb-6 flex mt-2">
      <UiFilterBox :options="sections" class="justify-center" />
    </div>
  </div>
</template>

<script setup>
import CalendarActionsWrapper from '@src/components/Calendar/CalendarActionsWrapper.vue'
import Heading from '@/src/components/Heading.vue'
import icon from '@src/components/icons/icon.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import GraphView from '@src/router/views/graph/graphView.vue'
import { generateRandomArray } from '@utils/generalUtil.js'

const sections = [
  { title: 'Campus for Testing' },
  { title: 'Lahore Campus' },
  { title: 'main Campus' },
]

const graphData = {
  type: 'bar',
  data: {
    labels: ['Exam Fee', 'Salaries', 'Book', 'Food', 'Picnic', 'Class Fee', 'Others'],
    datasets: [
      {
        label: 'January',
        backgroundColor: '#1570EF',
        data: generateRandomArray(7, 500, 800),
      },
      {
        label: 'February',
        backgroundColor: '#7F56D9',
        data: generateRandomArray(7, 500, 800),
      },
      {
        label: 'March',
        backgroundColor: '#FDB022',
        data: generateRandomArray(7, 500, 800),
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          padding: 13,
          font: {
            size: 14,
          },
          textAlign: 'center',
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    barPercentage: 0.6,
    categoryPercentage: 0.2,
    aspectRatio: 100 / 80,
    borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 0,
      bottomRight: 0,
    },
    borderSkipped: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          stepSize: 200,
        },
        beginAtZero: true,
      },
    },
  },
}
</script>
