<template>
  <div class="flex items-center justify-end w-full">
    <div class="text-xs text-primary-gray-500 font-medium">{{ title }}</div>
    <div class="text-xs text-primary-gray-700 font-medium ml-1">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'UiDashboardStats',
  props: {
    title: { type: String, default: '' },
    value: { type: String, default: '' },
  },
}
</script>
