<template>
  <div class="bg-white h-auto px-5 rounded-lg">
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Period Attendance" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <CalendarActionsWrapper />
      </div>
    </div>
    <div class="overflow-auto">
      <GraphView :chart-data="graphData" :enable-datalabels="enableDatalabels" class="min-w-7" />
    </div>
    <div class="pb-6 flex mt-2">
      <UiFilterBox :options="sections" />
      <UiDashboardStats title="Total Students:" value="45" />
    </div>
  </div>
</template>

<script setup>
import Heading from '@/src/components/Heading.vue'
import GraphView from '@src/router/views/graph/graphView.vue'
import CalendarActionsWrapper from '@src/components/Calendar/CalendarActionsWrapper.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import UiDashboardStats from '@/src/components/UiElements/UiStatsCountDisplay.vue'
import icon from '@src/components/icons/icon.vue'
import { generateRandomArray } from '@utils/generalUtil'

const periodAttendanceHeading = 'Period Attendance'
const sections = [
  { title: 'Campus for Testing' },
  { title: 'Lahore Campus' },
  { title: 'main Campus' },
]

const graphData = {
  startTime: { Hours: 8, Minutes: 15 },
  type: 'bar',
  data: {
    labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    datasets: [
      {
        label: ['English', 'Urdu', 'Physics', 'empty', 'Math', 'Arabic'],
        backgroundColor: ['#7F56D9', '#12B76A', '#F79009', 'white', '#D92D20', '#1570EF'],
        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
      {
        label: ['Urdu', 'Physics', 'empty', 'Math', 'Arabic', 'English'],

        backgroundColor: ['#12B76A', '#F79009', 'white', '#D92D20', '#1570EF', '#7F56D9'],

        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
      {
        label: ['Physics', 'empty', 'Math', 'Arabic', 'English', 'Urdu'],

        backgroundColor: ['#F79009', 'white', '#D92D20', '#1570EF', '#7F56D9', '#12B76A'],

        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
      {
        label: ['empty', 'Math', 'Arabic', 'English', 'Urdu', 'Physics'],

        backgroundColor: ['white', '#D92D20', '#1570EF', '#7F56D9', '#12B76A', '#F79009'],

        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
      {
        label: ['Math', 'Arabic', 'English', 'Urdu', 'Physics'],

        backgroundColor: ['#D92D20', '#1570EF', '#7F56D9', '#12B76A', '#F79009', 'white'],

        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
      {
        label: ['Arabic', 'English', 'Urdu', 'Physics', 'empty', 'Math'],
        backgroundColor: ['#1570EF', '#7F56D9', '#12B76A', '#F79009', 'white', '#D92D20'],

        data: generateRandomArray(6, 30, 60),
        pres: generateRandomArray(6, 50, 100),
        base: 0,
      },
    ],
  },
  options: {
    responsive: true,
    maxBarThickness: 62,
    maintainAspectRatio: false,
    aspectRatio: 100 / 200,
    margin: 10,
    borderRadius: {
      topLeft: 10,
      topRight: 10,
      bottomLeft: 0,
      bottomRight: 0,
    },
    borderSkipped: false,
    scales: {
      x: {
        grid: { display: false },
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 30,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        align: 'start',
        anchor: 'end',
        color: 'white',
        font: {
          size: 12,
          weight: 500,
        },
      },
    },
  },
}
const enableDatalabels = true
</script>
